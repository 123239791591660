.gradientOverlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    z-index: 1;
}

.containerSectionRegister {
    display: flex;
    overflow-y: auto;
    width: 100%;
    /* Ensure the container takes full width */
}

.containerSectionRegister::-webkit-scrollbar {
    display: none;
}

.containerSectionRegister::-webkit-scrollbar-track {
    background: #241818;
    border-radius: 10px;
}

.containerSectionRegister::-webkit-scrollbar-thumb {
    display: none;
}


.formularioRegistro {
    background-color: #222A42;
    padding: 20px;
    z-index: 2;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.formularioRegistro form {
    width: 100%;
}

.formularioRegistro .form-group {
    margin-bottom: 15px;
}

.formularioRegistro .form-group label {
    font-weight: bold;
}

.formularioRegistro .btn {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
}